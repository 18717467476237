<template>
  <v-card>
    <v-card-title>
      Nexmo SMS Log
    </v-card-title>

    <v-card-text>
      SMS Logs for Nexmo will be shown here if the application is configured to store the logs. 

      <v-row>
        <v-col>
          <v-text-field
            label="Page Number"
            v-model="pageNumber"
            type="number"
          />
        </v-col>

        <v-col>
          <v-select
            label="Logs Per Page"
            :items="[10, 25, 50, 100]"
            v-model="limit"
            @input="loadData(pageNumber)"
          />
        </v-col>
      </v-row>

      <v-btn color="primary" @click="loadData(pageNumber)">Load</v-btn>      

      <v-data-table v-if="data.data"
        :headers="headers"
        :items="data.data"
        :search="search"
        :items-per-page="data.data.length"
      />
    </v-card-text>

    <v-pagination class="text-xs-center mt-5 pb-4" v-if="data.totalDocs"
      @input="pageNumber => loadData(pageNumber)"
      :length="Math.ceil(data.totalDocs/limit)"
      :total-visible="10"
      v-model="data.page"
    ></v-pagination>
  </v-card>
</template>


<script>
import RestResource from "@/services/dataServiceAdmin.js";

const service = new RestResource();

export default {
  data() {
    return {
      pageNumber: 1,
      search: null,
      limit: 10,
      loaded: false,
      data: {},
      headers: [
        { text: "MessageID", value: "messageId", sortable: true },
        { text: "Number", value: "number", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "Timestamp", value: "timestamp", sortable: true },
      ],
    };
  },

  mounted() {
    this.loadData(1)
  },
  methods: {
    loadData(pageNumber = 1) {
      this.$setLoader()
      this.pageNumber = pageNumber

      service.listNexmoSmsLog({ page: pageNumber, limit: this.limit }).then(r => {
        this.data = r.data
        this.$disableLoader()
        this.loaded = true
      })
    }
  }
};
</script>